import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
  MatCardModule,
  MatInputModule,
  MatButtonModule,
  MatFormFieldModule,
  MatIconModule,
  MatDialogModule,
  MatTooltipModule
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeComponent } from './pages/home/home.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { SocialBarComponent } from './components/social-bar/social-bar.component';
import { ImprintComponent } from './components/imprint/imprint.component';
import { FooterComponent } from './components/footer/footer.component';
import { WhatIOfferComponent } from './components/what-ioffer/what-ioffer.component';
import { WhatIWorkWithComponent } from './components/what-iwork-with/what-iwork-with.component';
import { WebsiteDialogComponent } from './components/dialogs/website-dialog/website-dialog.component';
import { MobileDialogComponent } from './components/dialogs/mobile-dialog/mobile-dialog.component';
import { ResponsiveDialogComponent } from './components/dialogs/responsive-dialog/responsive-dialog.component';
import { DatenschutzComponent } from './components/dialogs/datenschutz/datenschutz.component';
import { AboutMeComponent } from './components/about-me/about-me.component';
import { BottomBannerComponent } from './components/bottom-banner/bottom-banner.component';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from 'src/environments/environment';


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.base_url
  },
  position: 'bottom-right',
  theme: 'classic',
  palette: {
    popup: {
      background: '#252e39'
    },
    button: {
      background: '#14a7d0'
    }
  },
  type: 'opt-out',
  content: {
    message: 'Diese Website verwendet Cookies – nähere Informationen dazu und zu Ihren Rechten als Benutzer\
     finden Sie in unserer Datenschutzerklärung am Ende der Seite.\
    Klicken Sie auf „Cookies akzeptieren“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.',
    allow: 'Cookies akzeptieren',
    deny: 'Cookies deaktivieren',
    link: 'Datenschutzerklärung',
    href: '/privacy',
    policy: 'Cookie-Informationen'
  }
};


@NgModule({
  declarations: [
    AppComponent,
    HeroComponent,
    HomeComponent,
    NavigationComponent,
    ContactCardComponent,
    SocialBarComponent,
    ImprintComponent,
    FooterComponent,
    WhatIOfferComponent,
    WhatIWorkWithComponent,
    WebsiteDialogComponent,
    MobileDialogComponent,
    ResponsiveDialogComponent,
    DatenschutzComponent,
    AboutMeComponent,
    BottomBannerComponent,
  ],
  entryComponents: [
    ImprintComponent,
    MobileDialogComponent,
    WebsiteDialogComponent,
    ResponsiveDialogComponent,
    DatenschutzComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    HttpClientModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
