import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgcCookieConsentService, NgcNoCookieLawEvent, NgcStatusChangeEvent, NgcInitializeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/de';
declare var gaOptout: Function;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private statusChangeSubscription: Subscription;

  constructor(private ccService: NgcCookieConsentService) { }
  ngOnInit() {
    moment.locale('de');
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status === 'deny') {
          // No cookies!
          gaOptout();
          location.reload();
          // window.reload!
        } else {
          // remove noCookie from localStorage!
            const name = 'ga-disable-UA-114295354-1';
            const d = new Date();
            d.setTime(d.getTime() - (1000 * 60 * 60 * 24));
            const expires = 'expires=' + d.toUTCString();
            window.document.cookie = name + '=' + '; ' + expires;
            location.reload();
        }
      });
  }
  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }
}
