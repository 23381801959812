import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message } from '../models/message';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SendMailService {

  constructor(private http: HttpClient) { }


  send(message: Message) {

    const requestBody = JSON.stringify(message);

    return this.http.post('https://api.kevin-kraus.com/home/', requestBody, { responseType: 'json' });
  }
}
