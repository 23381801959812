import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher, MatListIconCssMatStyler } from '@angular/material';
import { Message } from 'src/app/models/message';
import Swal from 'sweetalert2';
import { SendMailService } from 'src/app/services/send-mail.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})

export class ContactCardComponent implements OnInit {
  sentEmail = false;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  messageFormControl = new FormControl('', [
    Validators.required
  ]);

  firstNameFormControl = new FormControl('', [
    Validators.required
  ]);
  lastNameFormControl = new FormControl('', [
    Validators.required
  ]);

  matcher = new MyErrorStateMatcher();
  submitted = false;
  constructor(private mail: SendMailService) { }

  model = new Message('', '', '', '');
  anyError() {
    if (this.emailFormControl.hasError('email') || this.emailFormControl.hasError('required')
      || this.messageFormControl.hasError('required') || this.firstNameFormControl.hasError('required')
      || this.lastNameFormControl.hasError('required')) {
      return true;
    } else {
      return false;
    }
  }


  onSubmit(a: string) {
    Swal({
      title: 'E-Mail bestätigen',
      text: 'Ist ' + this.model.senderEmailAddress + ' korrekt?',
      type: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Bestätigen',
      cancelButtonText: 'Bearbeiten',
      showLoaderOnConfirm: true
    }).then((result) => {
      if (result.value) {
        this.mail.send(this.model).subscribe((response: any) => {
          if (response.status === 'successful') {
            // Google Analytics - Track Submit of ContactForm
            window['dataLayer'].push({'event': 'contact-form-was-submitted'});
            Swal({
              type: 'success',
              title: 'Vielen Dank!',
              text: 'Ihre Anfrage wurde verschickt!\
Ich werde mich innerhalb der nächsten Tage unter der angegebenen E-Mail Adresse (' + this.model.senderEmailAddress + ') bei Ihnen melden!'
            });
            this.sentEmail = true;
          } else if (response.status === 'error') {
            Swal({
              type: 'error',
              title: 'Ups!',
              text: 'Da ist leider etwas schiefgegangen :(... probieren Sie es doch bitte noch einmal!'
            });
          } else {
            Swal({
              type: 'error',
              title: 'Unbekannter Fehler'
            });
          }
        });
      }
    });
  }

  ngOnInit() {
  }

}
