import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {
  headers = [
    'Brauchen Sie eine Website?',
    'Website gesucht?',
    'Sie wollen eine Website?',
    'Wollen Sie eine Website?',
    'Programmierer gesucht?'
  ];

  subheaders = [
    'Kein Problem, schreiben Sie mir jetzt hier eine Nachricht und zusammen finden wir garantiert eine Lösung für Ihr Problem!',
  ];
  constructor() { }
  header: string;
  subheader: string;
  ngOnInit() {
    const randomNumber = Math.floor(Math.random() * this.headers.length);
    this.header = this.headers[randomNumber];
    this.subheader = this.subheaders[0];
  }

}
