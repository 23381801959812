import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  constructor() { }
  aboutMe = 'Über mich';
  references = 'Referenzen';
  offers = 'Angebot';
  contact = 'Kontakt';

  ngOnInit() {
  }

}
