import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ImprintComponent } from '../imprint/imprint.component';
import { ContactCardComponent } from '../contact-card/contact-card.component';
import { DatenschutzComponent } from '../dialogs/datenschutz/datenschutz.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  version = environment.VERSION;
  currentYear = Date.now();
  constructor(public dialog: MatDialog) { }

  openImprint() {
    const dialogRef = this.dialog.open(ImprintComponent, {
    });
  }

  openDatenschutz() {
    const dialogRef = this.dialog.open(DatenschutzComponent, {
    });
  }

  ngOnInit() {
  }

}
