import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { WebsiteDialogComponent } from '../dialogs/website-dialog/website-dialog.component';
import { MobileDialogComponent } from '../dialogs/mobile-dialog/mobile-dialog.component';
import { ResponsiveDialogComponent } from '../dialogs/responsive-dialog/responsive-dialog.component';

@Component({
  selector: 'app-what-ioffer',
  templateUrl: './what-ioffer.component.html',
  styleUrls: ['./what-ioffer.component.scss']
})
export class WhatIOfferComponent implements OnInit {

  constructor(public dialog: MatDialog) { }
  openWebsiteDialog() {
    // Google Analytics - Track Click on Website Banner
    window['dataLayer'].push({'event': 'website-banner-clicked'});
    const dialogRef = this.dialog.open(WebsiteDialogComponent, {
    });
  }
  openMobileDialog() {
    // Google Analytics - Track Click on Mobile Banner
    window['dataLayer'].push({'event': 'mobile-banner-clicked'});
    const dialogRef = this.dialog.open(MobileDialogComponent, {
    });
  }
  openResponsiveDialog() {
    // Google Analytics - Track Click on Responsive Banner
    window['dataLayer'].push({'event': 'responsive-banner-clicked'});
    const dialogRef = this.dialog.open(ResponsiveDialogComponent, {
    });
  }
  ngOnInit() {
  }

}
