import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-bar',
  templateUrl: './social-bar.component.html',
  styleUrls: ['./social-bar.component.scss']
})
export class SocialBarComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

  trackGitHubClick() {
    // Google Analytics - Track Click on Github Link
    window['dataLayer'].push({'event': 'github-link-clicked'});
  }
  trackLinkedInClick() {
    // Google Analytics - Track Click on LinkedIn Link
    window['dataLayer'].push({'event': 'linkedin-link-clicked'});
  }
  trackXingClick() {
    // Google Analytics - Track Click on Xing Link
    window['dataLayer'].push({'event': 'xing-link-clicked'});
  }
  trackFiverrClick() {
    // Google Analytics - Track Click on Fiverr Link
    window['dataLayer'].push({'event': 'fiverr-link-clicked'});
  }
}
