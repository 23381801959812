import { Component, OnInit, ModuleWithComponentFactories } from '@angular/core';
import * as moment from 'moment';
@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styleUrls: ['./about-me.component.scss']
})
export class AboutMeComponent implements OnInit {
  age = moment(['2000-01-19']).toNow(true);
  constructor() { }

  ngOnInit() {
  }

}
