import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
@Component({
  selector: 'app-bottom-banner',
  templateUrl: './bottom-banner.component.html',
  styleUrls: ['./bottom-banner.component.scss']
})
export class BottomBannerComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  scrollToHero() {
    // Google Analytics - Track Click on Bottom Banner
    window['dataLayer'].push({'event': 'bottom-banner-clicked'});
    const hero = document.getElementById('hero-contact');
    const firstNameInput = document.getElementById('mat-input-0');
    hero.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    firstNameInput.focus();
  }


  ngOnInit() {
  }

}
